import Carousel from 'react-bootstrap/Carousel';

var heroData = [
    {
        id: 1,
        image: require('../assets/images/img-hero1.jpg'),
        title: 'Internet que funciona para todos',
        description: '',
        link: 'https://www.consured.com'
    },
    {
        id: 2,
        image: require('../assets/images/img-hero2.jpg'),
        title: 'Privacidad y seguridad primero',
        description: '',
        link: 'https://www.consured.com'
    },
    {
        id: 3,
        image: require('../assets/images/img-hero3.jpg'),
        title: 'El Internet de la comunidad',
        description: '',
        link: 'https://www.consured.com'
    }
]

export default function AppHero() {
    return (
        <section id='home' className='hero-block'>
            <Carousel>{
                heroData.map(hero => {
                    return (
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={hero.image}
                            alt={"Slide " + hero.id}
                        />
                        <Carousel.Caption>
                            <h2>{hero.title}</h2>
                            <p>{hero.description}</p>
                            <a className="btn btn-primary" href={hero.link}>Ver Mas<i className="fas fa-chevron-right"></i></a>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
                })}
            </Carousel>
        </section>
    )
}