import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import AppHeader from './components/header';
import AppHero from './components/hero';
import About from './components/about';
import AppServices from './components/internet';
import AppPlans from './components/plans';
import AppCompanyPlans from './components/company_plans';
import AppContact from './components/contact';
import AppTeams from './components/team';




function App() {
  return (
    <div className="App">
      <header id='header'>
        <AppHeader />
      </header>
      <main>
        <AppHero/>
        <About/>
        {/* <AppServices/> */}
        <AppPlans/>
        <AppCompanyPlans/>
        <AppTeams/>
        <AppContact/>
  
      </main>

    </div>
  );
}

export default App;
