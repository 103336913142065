import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/img1.jpg';


export default function AppAbout() {

  
    const optic_fiber = 70;
    const antenna = 30;
    return (
        <section id="about" className='block about-block'>
            <Container fluid>
                <div className="title-holder">
                    <h2>Quienes somos</h2>
                    <div className="subtitle">Aprenda mas acerca de nosotros</div>
                </div>
                <Row>
                    <Col sm={6}>
                        <Image src={img1} />
                    </Col>
                    <Col sm={6}>
                        <p style={{textAlign:'justify'}}>Consured es una empresa familiar, creada hace más de 10 años como respuesta a la necesidad de diferentes comunidades rurales del sur de Jamundí. Comunidades que se encontraban incomunicadas digitalmente y en ese entonces con difícil acceso terrestre. Para ese entonces el fundador de Consured se traslada a vivir a esa área y al ver la necesidad de comunicación inicia la dura labor de buscar los medios tecnológicos y económicos para llevar el internet a la comunidad.
Por muchos años Consured brindo conectividad a comunidades muy pequeñas que no resultaban atractivas paras las grandes empresas de comunicación. Con el tiempo esta situación cambio y hoy coexistimos con gigantes proveedores de comunicación, pero Consured tiene la satisfacción de poder servir a la comunidad de una manera que nadie lo hace ya que Consured tiene contacto directo con la comunidad y participación directa en su desarrollo. Hoy Consured paso de ofrecer exclusivamente conexiones por medio de enlaces inalámbricos a llegar a una gran parte de la comunidad por medio de fibra óptica, pudiendo así ofrecer planes de banda ancha del nivel de las grandes ciudades.  El crecimiento le ha permitido a Consured crear empleos estables que son siempre ofrecidos principalmente a la misma comunidad donde tenemos cobertura. 
</p> <p>La clave del éxito de Consured radica en 3 pilares:</p><p>
Atención personalizada, eficaz y eficiente a nuestros clientes. <br/>
Capacitación y estudio continuo por parte de todos los colaboradores de Consured.<br/>
Ofrecer servicios de última tecnología y alta calidad.<br/> </p><p style={{textAlign:'justify'}}>
Es así como Consured se proyecta para ser la mejor empresa de comunicaciones del sur Occidente de Colombia. 
</p>
                       
                        <div className='progress-block'>
                            <h4>Fibra optica</h4>
                            <ProgressBar now={optic_fiber} label={`${optic_fiber}%`} />
                        </div>
                        <div className='progress-block'>
                            <h4>Antenas</h4>
                            <ProgressBar now={antenna} label={`${antenna}%`} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}