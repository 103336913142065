import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AppContact() {
    return (
        <section id="contact" className="block contact-block">
            <Container fluid>
                <div className="title-holder">
                    <h2>Contactenos</h2>
                    <div className="subtitle">Este conectado con nosotros</div>
                </div>
                <Form className='contact-form'>
                    <Row>
                        <Col sm={4}>
                            <Form.Control type="text" placeholder="Escriba su nombre completo" required />
                        </Col>
                        <Col sm={4}>
                            <Form.Control type="email" placeholder="Escriba su correo electronico" required />
                        </Col>
                        <Col sm={4}>
                            <Form.Control type="tel" placeholder="Escriba su numero de contacto" required />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Control as="textarea" placeholder="Escriba su mensaje" required />
                        </Col>
                    </Row>
                    <div className='btn-holder'>
                        <Button type="submit">Enviar</Button>
                    </div>
                </Form>
            </Container>
            <div className='google-map'>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d248.96790006512296!2d-76.5828792!3d3.2283916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e309bc7f7e2262b%3A0x1b2d5c92af37d33a!2sOficina%20Consured!5e0!3m2!1sen!2sus!4v1696865483353!5m2!1sen!2sus" ></iframe>
            </div>
            <Container fluid>
                <div className='contact-info'>
                    <ul>
                        <li>
                        <a href='mailto:contacto@consured.com'> 
                            <i className="fas fa-envelope"></i>
                             contacto@consured.com</a>
                        </li>
                        <li><a href='tel:+576024864624'> 
                            <i className="fas fa-phone"></i>
                            +57 602-486-4624 </a>
                        </li>
                        <li>
                            <a href='https://api.whatsapp.com/send?phone=573162721183'>
                            <i className="fa-brands fa-whatsapp"></i>
                            +57 316-272-1183
                            </a>
                        </li>
                        <li>
                            <i className="fas fa-map-marker-alt"></i>
                            Potrerito, Jamundi, Colombia
                        </li>
                    </ul>
                </div>
            </Container>
        </section>
    );
}

export default AppContact;