import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

const pricingData = [
    {
        id: 1,
        plan: '100 Megas',
        price: '$50.000',
        features: ['Velocidad simetrica', 'Estratos 1, 2 y 3'],
        pricetax: '$59.500',
        featurestax: ['Estratos 4, 5 y 6'],
        conditions: ['Instalacion Gratis'],
        link: 'https://www.google.com'
    },
    {
        id: 2,
        plan: '200 MEGAS',
        price: '$60.000',
        features: ['Velocidad simetrica', 'Estratos 1, 2 y 3'],

        pricetax: '$71.400',
        featurestax: ['Estratos 4, 5 y 6'],
        conditions: ['Instalacion Gratis'],

        link: 'https://www.facebook.com'
    },
    {
        id: 3,
        plan: '400 MEGAS',
        price: '$82.000',
        pricetax: '$97.580',
        featurestax: ['Simetrico', 'Estratos 4, 5 y 6'],
        conditions: ['Instalacion Gratis'],

        features: ['Velocidad simetrica', 'Estratos 1, 2 y 3'],
        link: 'https://www.twitter.com'
    }
]

function AppPlans() {
    return (
        <section id="home_plans" className="block pricing-block">
            <Container fluid>
                <div className="title-holder">
                    <h2>Precios &amp; Planes Hogar</h2>
                    <div className="subtitle">Revisa nuestros precios &amp; planes</div>
                </div>
                <Row>
                    {
                        pricingData.map(pricing => {
                            return (
                                <Col sm={4} key={pricing.id}>
                                    <div className='heading'>
                                        <h3>{pricing.plan}</h3>
                                        <span className='price'>{pricing.price}</span>
                                    </div>

                                    <div className='content'>
                                        <ListGroup>
                                            {
                                                pricing.features.map((feature, index) => {
                                                    return (
                                                        <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                                                    );
                                                })
                                            }
                                        </ListGroup>
                                    </div>
                                    <div className='heading'>
                                        <span className='price'>{pricing.pricetax}</span>
                                    </div>
                                    <div className='content'>
                                        <ListGroup>
                                            {
                                                pricing.featurestax.map((feature, index) => {
                                                    return (
                                                        <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                                                    );
                                                })
                                            }
                                        </ListGroup>
                                    </div>
                                    <div className='btn-holder'>
                                        <a href={pricing.link} className='btn btn-primary'>Solicitar ahora</a>
                                    </div>
                                    <div className='content'>
                                        <ListGroup>
                                            {
                                                pricing.conditions.map((feature, index) => {
                                                    return (
                                                        <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                                                    );
                                                })
                                            }
                                        </ListGroup>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            
        </section>
    )
}

export default AppPlans;